<app-top-bar></app-top-bar>

<div class="container">
  <div class="container__map toolbox">
    <app-map></app-map>
  </div>
  <div class="container__tools toolbox">
    <router-outlet></router-outlet>
  </div>

</div>
