<h1>{{ satellite.satname }}</h1>
<p>
  <span class="bold">Intl. Designation: </span>{{ satellite.intDesignator }}
  <br />
  <span class="bold">Norad ID: </span>{{ satellite.satid }} <br />
  <span class="bold">Launched: </span>{{ satellite.launchDate }} <br />
  <span class="bold">Alt: </span>{{ satellite.satalt }} Km <br />
  <span class="bold">Lat: </span>{{ satellite.satlat }} <br />
  <span class="bold">Lng: </span>{{ satellite.satlng }} <br />
</p>

<p>
  <button class="backButton" onclick="window.history.back();">Go Back</button>
</p>
