<div [ngClass]="{ hidden: satellites }">Waiting</div>
<h1>Satellites</h1>

<div class="satList">
  <div *ngIf="satellites; else noSateleites">
    <div *ngFor="let satellite of satellites; index as satId">
      <p>
        <a
          [title]="satellite.satname + ' details'"
          [routerLink]="['/satellite', satellite.satid]"
        >
          {{ satellite.satname }} ({{ satellite.satid }})
        </a>
      </p>
    </div>
  </div>
  <ng-template #noSateleites>
    <p>There are no satellites currently visible from here.</p>
  </ng-template>
</div>
<p>
  <button class="backButton" onclick="window.history.back();">Go Back</button>
</p>
